import jQuery from 'jquery';
import 'owl.carousel';

(function($) {
    "use strict";

    // Preloader
    function preloader() {
        return;
        if( $('.preloader').length ){
            $(window).on('load', function() {
                $('.preloader').fadeOut();
                $('.preloader').delay(50).fadeOut('slow');
            });
        };
    }
    preloader();


    // Testimonial Carousel
    function testimonial_carousel() {
        $('#customers-testimonials').owlCarousel({
            loop: true,
            center: true,
            items: 3,
            margin: 0,
            nav: true,
            dots: true,
            autoplayTimeout: 8500,
            smartSpeed: 450,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1170: {
                    items: 3
                }
            }
        });
    }
    testimonial_carousel();

    // Faq Page Accordions
    function faq_accordion() {
        $('.collapse').on('show.bs.collapse', function () {
            $(this).siblings('.card-header').addClass('active');
        });

        $('.collapse').on('hide.bs.collapse', function () {
            $(this).siblings('.card-header').removeClass('active');
        });
    }
    faq_accordion();



}) (jQuery);
